// NOTE: effect_price just 特例，迁移旧官网遗留路由
import { Billboard, SEO, Wrapper } from '@renderbus/common/components'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Layout from '../molecules/layout'
import RegisterBanner from '../molecules/register-banner'
import {
  CardContent,
  CardPrice,
  CardPriceTag,
  CardSubTitle,
  CardTitle,
  CardTopPrice,
  ContentContainer,
  ContentSubTitle,
  ContentTitle,
  ContentTitleH1,
  DescriptionText,
  MoreButtonContainer,
  RenderCard,
  RenderCardContainer,
  BannerWrapper,
  ContentTitleWrapper,
  Slogan1,
  Slogan2,
  PriceWrapper,
  VipActivityContent,
  Separator,
  CardButton,
  TipWrapper,
  MoreButton,
  SloganWrapper,
  CardFreeSample,
} from './effect-price.atom'
import { Diamond } from '../molecules/icons'
import { baseLink } from '@renderbus/common/service'
// import Customized from '../images/effect-price/customized.png'
import CarrotCoupon from '../molecules/effect-price/carrot-coupon'
import { useMedia } from 'use-media'

class EffcetPrice extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hoverCard: 1,
    }
  }
  enterCard(cardId) {
    this.setState({
      hoverCard: cardId,
    })
  }
  handleClick(href) {
    window.location.href = href
  }
  render() {
    const {
      location,
      pageContext: { topThreeShare },
    } = this.props
    const Wechat_Customer_Service =
      'https://webpage.qidian.qq.com/2/chat/pc/index.html?linkType=1&env=ol&kfuin=3009125740&fid=102910&key=3294247a7b084e5a84bc841d457b41cb&cate=7&source=0&isLBS=0&isCustomEntry=0&type=10&ftype=1&_type=wpa&qidian=true&translateSwitch=0&isSsc=0&roleValue=5&roleData=232'
    return (
      <StaticQuery
        query={graphql`
          query {
            banner: file(relativePath: { regex: "/effect-price/banner-bg.png/" }) {
              ...fluidImage
            }
            bannerMb: file(relativePath: { regex: "/effect-price/banner-bg-mobile.png/" }) {
              ...fluidImage
            }
            coupon: file(relativePath: { regex: "/effect-price/coupon.png/" }) {
              ...fluidImage
            }
          }
        `}
        render={({ banner, bannerMb }) => (
          <Layout location={location} topThreeShare={topThreeShare}>
            <SEO
              title='效果图渲染价格,渲染一张效果图多少钱-Renderbus云渲染农场'
              keywords='效果图渲染价格,效果图云渲染'
              description='Renderbus云渲染农场提供效果图渲染报价,云渲染超值性价比,会员每月免单4张图,渲染价格低至0.03元/分钟,封顶价20元/张,注册送20元无门槛渲染券,欢迎立即体验~'
            />
            <ContentContainer theme='black' id='price-middle'>
              <BannerWrapper>
                <ContentTitleWrapper>
                  <Slogan1>
                    <span>新用户注册可领取</span>
                    <span className='slogan-has-image'>超45元新手大礼包</span>
                  </Slogan1>
                  <ContentTitleH1>
                    效果图云渲染
                    <span>全面降价</span>
                  </ContentTitleH1>
                  <SloganWrapper>
                    <Slogan2>多机分块渲染不加价</Slogan2>
                    <Slogan2>AI加速模式超值性价比</Slogan2>
                  </SloganWrapper>
                </ContentTitleWrapper>
                <Billboard
                  className='effect-price-billboard'
                  fluid={[
                    banner.childImageSharp.fluid,
                    { ...bannerMb.childImageSharp.fluid, media: `(max-width: 780px)` },
                  ]}
                />
              </BannerWrapper>
              <PriceWrapper>
                <RenderCardContainer>
                  <RenderCard>
                    <CardTopPrice>封顶价：25元/张</CardTopPrice>
                    <CardTitle className='not-vip'>非会员</CardTitle>
                    <div>
                      <CardSubTitle>
                        <CardPriceTag>￥</CardPriceTag>
                        <CardPrice>0.04</CardPrice>/分钟
                      </CardSubTitle>
                    </div>
                    <CardFreeSample>每周一张免费小样</CardFreeSample>
                    <CardButton
                      id='EP-nonmember'
                      href={`${baseLink()}/center/user/topUp`}
                      // href={`${baseLink()}/sso/register`}
                      target='_blank'
                      rel='nofollow'
                      variant={'outlined'}
                    >
                      立即体验
                    </CardButton>
                  </RenderCard>
                  <RenderCard isVip>
                    <CardTopPrice>封顶价：20元/张</CardTopPrice>
                    <CardTitle>会员</CardTitle>
                    <div>
                      <CardSubTitle>
                        <CardPriceTag>￥</CardPriceTag>
                        <CardPrice>0.03</CardPrice>/分钟
                      </CardSubTitle>
                    </div>
                    <CardContent>
                      <p>效果图会员大回馈</p>
                      <p>19.9尽享三重礼</p>
                    </CardContent>
                    <VipActivityContent>
                      <Separator>
                        <legend className='inner'>会员期间</legend>
                      </Separator>
                      <div>
                        <AdaptDiamond />
                        <span>每天一张免费小样</span>
                      </div>
                      <div>
                        <AdaptDiamond />
                        <span>每月免单4张图 (不限金额)</span>
                      </div>
                      <div>
                        <AdaptDiamond />
                        <span>单张渲染20元封顶</span>
                      </div>
                      <div>
                        <AdaptDiamond />
                        <span>渲染享0.03元/分钟</span>
                      </div>
                    </VipActivityContent>
                    <CardButton
                      id='EP-membership'
                      variant={'outlined'}
                      href={`${baseLink()}/center/user/topUp`}
                      target='_blank'
                      rel='nofollow'
                    >
                      立即体验
                    </CardButton>
                  </RenderCard>
                  <RenderCard
                    onMouseEnter={() => {
                      this.enterCard(2)
                    }}
                  >
                    <CardTitle className='more-requirement'>更多需求</CardTitle>
                    <CardContent>
                      <p>更多特殊要求</p>
                      <p>更多价格优惠</p>
                    </CardContent>
                    <CardButton
                      id='EP-contactus'
                      href={Wechat_Customer_Service}
                      target='_blank'
                      rel='nofollow'
                      variant={'outlined'}
                    >
                      联系我们
                    </CardButton>
                  </RenderCard>
                </RenderCardContainer>
                <DescriptionText>
                  <p>价格说明： </p>
                  <p>在平台提交的作业默认会分块渲染，每块的任务出图即扣费，不出图不会扣费。</p>
                </DescriptionText>
                <DescriptionText className='description'>
                  <p>扣费规则说明：</p>
                  <div className='description-content'>
                    <div>
                      <p>1. 价格：现会员 0.03元/分钟，非会员0.04元/分钟</p>
                      <p>2. 单块任务扣费：价格 * 时间(备注：时间单位是分钟)</p>
                      <p>3. 任务费用：分块数量 * 单块任务费用</p>
                      <p>
                        4.
                        AI加速模式任务费用：价格*渲染时间*(1+20%)（备注：AI加速模式目前仅支持单机渲染模式）
                      </p>
                    </div>
                    <div>
                      <p>5. 免费渲小样模式说明：</p>
                      <p>
                        5.1 规则说明：普通用户每月共4次免费渲小样机会
                        ，效果图会员每月共30次免费渲小样机会
                      </p>
                      <p>
                        5.2
                        功能说明：50W像素以下的测试图免费渲染最高一小时，分辨率超过50万系统默认自动优化参数
                      </p>
                      <p>
                        5.3 扣费说明:
                        规定次数范围内，用户提交的50W像素以下测试图渲染完成后系统将自动免单
                      </p>
                    </div>
                  </div>
                </DescriptionText>
              </PriceWrapper>
            </ContentContainer>
            <ContentContainer theme='grey' style={{ padding: '50px 0' }}>
              <Wrapper>
                <ContentTitle>首充特惠</ContentTitle>
                <ContentSubTitle>优惠后渲染单价低至0.025元/分钟！</ContentSubTitle>
                <RenderCardContainer>
                  <CarrotCoupon buttonId='EP-recharge58' recharge={50} free={8}></CarrotCoupon>
                  <CarrotCoupon buttonId='EP-recharge235' recharge={200} free={35}></CarrotCoupon>
                  <CarrotCoupon
                    buttonId='EP-recharge1175'
                    recharge={1000}
                    free={175}
                  ></CarrotCoupon>
                </RenderCardContainer>
                <MoreButtonContainer>
                  <MoreButton
                    href={Wechat_Customer_Service}
                    target='_blank'
                    variant='outlined'
                    rel='nofollow'
                  >
                    商务合作
                  </MoreButton>
                </MoreButtonContainer>
                <TipWrapper>
                  注：首充优惠三选一，仅限一次。赠券系统自动充值到账，效果图渲染用户专享
                </TipWrapper>
              </Wrapper>
            </ContentContainer>
            <RegisterBanner isPicture='true' />
          </Layout>
        )}
      />
    )
  }
}

export default EffcetPrice

export const AdaptDiamond = () => {
  const isMobile = useMedia({ maxWidth: 780 })
  return <Diamond width={isMobile ? 14 : 20} height={isMobile ? 14 : 20} />
}
